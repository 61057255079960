import { ApiService } from '@/services/ApiService'

export class FormService extends ApiService {
  postForm(form: any) {
    return this.axios.post('/data/forms', form).then((response) => {
      return response.data.result
    })
  }
}

export default new FormService()
